html {
  height: 100%
}
body {
  height: 100%
}
#root {
  height:100%;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
}
.pdf-button {
  position: fixed !important;
  bottom: 16px;
  right: 80px;
}
.share-button {
  position: fixed !important;;
  bottom: 16px;
  right: 144px;
}
.download-button {
  position: fixed !important;;
  bottom: 16px;
  right: 16px;
}


@media (max-width: 768px) {
  /* Mobile Styles */
  .pdf-button {
    position: fixed !important;
    bottom: 80px;
    right: 16px;
  }

  .share-button {
    position: fixed !important;;
    bottom: 144px;
    right: 16px;
  }
  .download-button {
    position: fixed !important;;
    bottom: 16px;
    right: 16px;
  }
}
